import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
// Components
import LoginForm from '@/app/components/login-form/LoginForm.vue';
import moment from 'moment';
@Component({
  name: 'app-login',
  components: { LoginForm },
})
export default class AppLogin extends VueWizard {
  async created(): Promise<any> {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'm0pvd5xx',

      chatcenter: 'SI',
    };
    (function () {
      let w = window;
      let ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        let d = document;
        let i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        let l = function () {
          let s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/m0pvd5xx';
          let x: any = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  get currentYear(): string {
    return moment().format('YYYY');
  }
}
