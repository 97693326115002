import { mapActions, mapMutations } from 'vuex';
import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { store, storeTypes } from '@/app/store';
//require('/src/assets/css/app.css');
@Component({
  name: 'login-form',
  methods: {
    ...mapMutations(['setUser']),
    ...mapActions(['login']),
  },
})
export default class LoginForm extends VueWizard {
  public loginForm = {
    username: '',
    password: '',
  };

  public rules = {
    username: [
      {
        required: true,
        message: 'Por favor ingrese un usuario',
        trigger: 'blur',
      },
      {
        min: 2,
        max: 50,
        pattern: /^[a-zA-Z ]{2,50}$/,
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
          // must be at least 2 characters, with message 1/50 , 2/50 while typing
          if (value === '') {
            callback(new Error('Ingrese un usuario'));
          } else if (value.length < 2) {
            callback(new Error('Minimo 2 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
        },
      },
    ],
    password: [
      {
        required: true,
        message: 'Por favor ingrese una contraseña',
        trigger: 'blur',
      },
    ],
  };

  setUser!: any;

  get loadingAuth() {
    return this.$store.getters.isAuthLoading;
  }

  async handleSubmit() {
    await store.dispatch(
      storeTypes.auth.actions.login({
        username: this.loginForm.username,
        password: this.loginForm.password,
      }),
    );
  }

  validateForm(prop: string, valid: boolean) {
    const form = this.$refs.loginForm as any;
    form.validate().then(async () => {
      await this.handleSubmit();
    });
  }
}
